import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useTheme,
} from "@chakra-ui/react"
import React from "react"
import { t } from "i18next"

interface Props {
  children?: React.ReactNode
  id?: string
  title: string
  description?: string
  isOpen: boolean
  confirmLabel?: string
  cancelLabel?: string
  isCancelVisible?: boolean
  isConfirmVisible?: boolean
  isCentered?: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmationModal: React.FC<Props> = ({
  id,
  title,
  description = "",
  isOpen,
  confirmLabel = t("components.ConfirmationModal.DefaultConfirmLabel"),
  cancelLabel = t("components.ConfirmationModal.DefaultCancelLabel"),
  isCancelVisible = true,
  isConfirmVisible = true,
  isCentered = false,
  onConfirm,
  onClose,
  children,
}) => {
  const {
    colors: { primary, gray },
  } = useTheme()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent padding={3}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {description}
          {children}
        </ModalBody>
        <ModalFooter>
          {isConfirmVisible && (
            <Button
              id={id ? `${id}-confirmation-confirm` : undefined}
              data-testid="confirm-button"
              mr={3}
              colorScheme="primary"
              color="primary.text"
              autoFocus={true}
              onClick={onConfirm}
              _focus={{
                boxShadow: `${primary[600]} 0 0 0 3px`,
              }}
            >
              {confirmLabel}
            </Button>
          )}
          {isCancelVisible && (
            <Button
              id={id ? `${id}-confirmation-cancel` : undefined}
              data-testid="cancel-button"
              colorScheme="gray"
              onClick={onClose}
              _focus={{
                boxShadow: `${gray[400]} 0 0 0 3px`,
              }}
            >
              {cancelLabel}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmationModal
